// Radio Button

// Colors
$color-1: #2ecc71;
$color-2: #3498db;
$color-3: #f1c40f;
$color-4: #e74c3c;

.custom-radios {
  div {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;

    + label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;

      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #ffffff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;

        img {
          opacity: 0;
          transition: all 0.3s ease;
        }
      }
    }

    &#color-1 + label span {
      background-color: $color-1;
    }

    &#color-2 + label span {
      background-color: $color-2;
    }

    &#color-3 + label span {
      background-color: $color-3;
    }

    &#color-4 + label span {
      background-color: $color-4;
    }

    &:checked + label span img {
      opacity: 1;
    }
  }
}

// V2

label > .radio-custom:checked ~ span::before {
  color: blue;
}

.set__index__high {
  position: relative;
  z-index: 10000 !important;
}

.radio-custom {
  padding-top: 10px;
}
